@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply underline;
}

button, input[type="submit"], input[type="text"] {
  @apply border rounded p-2;
}

h1, h2, h3, h4 {
  @apply font-bold;
}

h1 {
  @apply text-4xl
}

h2 {
  @apply text-3xl;
}

.react-autosuggest__container {
  @apply relative text-black;
}

.react-autosuggest__input {
  @apply w-full;
}

.react-autosuggest__input--focused {
  @apply outline-none;
}

.react-autosuggest__suggestions-container {
  @apply hidden;
}

.react-autosuggest__suggestions-container--open {
  @apply block absolute w-full bottom-10 border bg-white z-10 lg:bottom-auto lg:top-10;
}

.react-autosuggest__suggestions-list {
  @apply list-none;
}

.react-autosuggest__suggestion {
  @apply cursor-pointer border-b p-2 last:border-0;
}

.react-autosuggest__suggestion--highlighted {
  @apply bg-slate-300;
}
